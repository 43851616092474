/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*---------- Header-styles ----------*/
/*Header-Color*/
.color-header .app-header,
.color-header .hor-header {
    background: #3366ff;
}

.color-header .nav-link.toggle,
.color-header .header-right .nav-link.icon {
    background: rgba(0, 0, 0, 0.1);
}

.color-header .animated-arrow {
    background: rgba(0, 0, 0, 0.1);
}

.color-header .animated-arrow span {
    background: #e7e7f7;
}

.color-header .animated-arrow span:before,
.color-header .animated-arrow span:after {
    background: #e7e7f7;
}

.color-header.active .animated-arrow span {
    background-color: transparent;
}

.color-header .header .nav-link.icon i,
.color-header .header-right .nav-link.icon:hover i {
    color: #e7e7f7 !important;
}

.color-header .header .nav-link {
    color: #e7e7f7 !important;
}

.color-header .header .nav-link:hover {
    color: #fff;
}

.color-header .header .form-inline .form-control {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background: rgba(0, 0, 0, 0.08);
    color: #fff;
}

.color-header .header .form-inline .btn i {
    color: #fff !important;
    opacity: .5;
}

.color-header .header .form-inline .form-control::placeholder {
    color: #fff !important;
    opacity: .3;
}

.color-header .app-sidebar__toggle {
    background: rgba(0, 0, 0, 0.08);
}

.color-header .app-sidebar__toggle a {
    color: #fff;
}

.color-header .nav-link.icon {
    background: rgba(0, 0, 0, 0.08);
}

@media (min-width: 992px) {
    .color-header .hor-header.header .header-brand .header-brand-img.desktop-lgo {
        display: none;
    }

    .color-header .hor-header.header .header-brand .header-brand-img.dark-logo {
        display: block;
        margin-top: 5px;
    }
}

@media (max-width: 767px) {
    .color-header .app-header .header-brand-img.darkmobile-logo {
        display: block;
    }

    .color-header .app-header .header-brand-img.mobile-logo,
    .color-header .app-header .header-brand-img.desktop-lgo {
        display: none !important;
    }

    .color-header .hor-header.header .header-brand-img.mobile-logo {
        display: none !important;
    }

    .color-header .hor-header.header .header-brand-img.darkmobile-logo {
        display: block;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {

    .color-header .hor-header.header .header-brand-img.desktop-lgo,
    .color-header .hor-header.header .header-brand-img.mobile-logo {
        display: none !important;
    }

    .color-header .hor-header.header .header-brand-img.dark-logo {
        display: block !important;
        margin-top: 5px;
    }
}

.dark-mode.color-header .app-header,
.dark-mode.color-header .hor-header {
    background: #3366ff;
}

.dark-mode.color-header .header .nav-link.icon,
.dark-mode.color-header .animated-arrow {
    background: rgba(0, 0, 0, 0.1);
}

.dark-mode.color-header .header .form-inline .form-control {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    color: #fff;
}

/*Header-dark*/
.dark-header .app-header,
.dark-header .hor-header {
    background: #17263a;
}

.dark-header .nav-link.toggle,
.dark-header .header-right .nav-link.icon {
    background: rgba(255, 255, 255, 0.05);
}

.dark-header .animated-arrow {
    background: rgba(255, 255, 255, 0.05);
}

.dark-header .animated-arrow span {
    background: #e7e7f7;
}

.dark-header .animated-arrow span:before,
.dark-header .animated-arrow span:after {
    background: #e7e7f7;
}

.dark-header.active .animated-arrow span {
    background-color: transparent;
}

.dark-header .header .nav-link.icon i,
.dark-header .header-right .nav-link.icon:hover i,
.dark-header .header .nav-link {
    color: #e7e7f7 !important;
}

.dark-header .app-header.header .nav-link:hover {
    color: #fff;
}

.dark-header .header .form-inline .form-control {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background: #1f324c;
    color: #fff;
}

.dark-header .header .form-inline .btn i {
    color: #fff;
    opacity: .5;
}

.dark-header .header .form-inline .form-control::placeholder {
    color: #fff;
    opacity: .3;
}

.dark-header .hor-header.header .header-brand-img.desktop-logo {
    display: none;
}

.dark-header .hor-header.header .header-brand-img.desktop-logo-1 {
    display: block;
}

.dark-header .app-sidebar__toggle {
    background: #1f324c;
}

.dark-header .app-sidebar__toggle a {
    color: #fff;
}

.dark-header .nav-link.icon {
    background: #1f324c;
}

@media (max-width: 768px) and (min-width: 575.98px) {
    .dark-header .app-header .header-brand .header-brand-img.dark-logo {
        display: block;
        margin-top: 5px;
    }

    .dark-header .app-header .header-brand .header-brand-img.desktop-lgo {
        display: none;
    }
}

@media (min-width: 992px) {
    .dark-header .hor-header.header .header-brand .header-brand-img.desktop-lgo {
        display: none;
    }

    .dark-header .hor-header.header .header-brand .header-brand-img.dark-logo {
        display: block;
        margin-top: 5px;
    }
}

@media (max-width: 768px) {
    .dark-header .hor-header.header .header-brand-img.desktop-logo-1 {
        display: none !important;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {

    .dark-header .hor-header.header .header-brand-img.desktop-lgo,
    .dark-header .hor-header.header .header-brand-img.mobile-logo {
        display: none !important;
    }

    .dark-header .hor-header.header .header-brand-img.dark-logo {
        display: block !important;
        margin-top: 5px;
    }
}

/*Header-light*/
.dark-mode.light-header .app-header,
.dark-mode.light-header .hor-header {
    background: #fff;
}

.dark-mode.light-header .app-sidebar {
    box-shadow: none;
}

.dark-mode.light-header .header .nav-link.icon,
.dark-mode.light-header .header-right .nav-link.icon,
.dark-mode.light-header .animated-arrow {
    background: #f1f5ff;
}

.dark-mode.light-header .animated-arrow span {
    background: #5c678f;
}

.dark-mode.light-header .animated-arrow span:before,
.dark-mode.light-header .animated-arrow span:after {
    background: #5c678f;
}

.dark-mode.light-header.active .animated-arrow span {
    background-color: transparent;
}

.dark-mode.light-header .header .form-inline .form-control {
    border: 1px solid transparent !important;
    background: #f1f5ff;
    color: #000;
}

.dark-mode.light-header .hor-header .header-brand-img.desktop-logo-1 {
    display: none;
}

.dark-mode.light-header .drop-profile a {
    color: #171a29;
}

.dark-mode.light-header .hor-header .header-brand-img.desktop-logo {
    display: block;
}

.dark-mode.light-header .header .nav-link.icon i {
    color: #17263a;
}

.dark-mode.light-header .app-sidebar__toggle {
    background: #f1f4fb;
}

.dark-mode.light-header .app-sidebar__toggle a {
    color: #555b95;
}

@media (max-width: 575.98px) {
    .dark-mode.light-header .header .form-inline .form-control {
        background: #191d43;
    }
}

@media (min-width: 992px) {
    .dark-mode.light-header .hor-header.header .header-brand .header-brand-img.dark-logo {
        display: none;
    }

    .dark-mode.light-header .hor-header.header .header-brand .header-brand-img.desktop-lgo {
        display: block;
        margin-top: 5px;
    }
}

@media (max-width: 767px) {
    .dark-mode.light-header .hor-header .header-brand-img.desktop-logo {
        display: none;
    }
}

@media (max-width: 768px) and (min-width: 568px) {
    .app.dark-mode.light-header .app-header .header-brand .header-brand-img.desktop-lgo {
        display: block;
        margin-top: 5px;
    }

    .app.dark-mode.light-header .app-header .header-brand .header-brand-img.dark-logo,
    .app.dark-mode.light-header .app-header .header-brand .header-brand-img.mobile-logo,
    .app.dark-mode.light-header .app-header .header-brand .header-brand-img.darkmobile-logo {
        display: none !important;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {

    .dark-mode.light-header .hor-header.header .header-brand-img.dark-logo,
    .dark-mode.light-header .hor-header.header .header-brand-img.mobile-logo {
        display: none !important;
    }

    .dark-mode.light-header .hor-header.header .header-brand-img.desktop-lgo {
        display: block !important;
        margin-top: 5px;
    }
}

/*Header-Gradient-header*/
.gradient-header .app-header,
.gradient-header .hor-header {
    background: linear-gradient(to left, #8133ff 0%, #3366ff 100%);
}

.gradient-header .nav-link.toggle,
.gradient-header .header-right .nav-link.icon {
    background: rgba(0, 0, 0, 0.1);
}

.gradient-header .animated-arrow {
    background: rgba(0, 0, 0, 0.1);
}

.gradient-header .animated-arrow span {
    background: #e7e7f7;
}

.gradient-header .animated-arrow span:before,
.gradient-header .animated-arrow span:after {
    background: #e7e7f7;
}

.gradient-header.active .animated-arrow span {
    background-color: transparent;
}

.gradient-header .header .nav-link.icon i,
.gradient-header .header-right .nav-link.icon:hover i {
    color: #e7e7f7 !important;
}

.gradient-header .header .nav-link {
    color: #e7e7f7 !important;
}

.gradient-header .header .nav-link:hover {
    color: #fff;
}

.gradient-header .header .form-inline .form-control {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background: rgba(0, 0, 0, 0.08) !important;
    color: #fff;
}

.gradient-header .header .form-inline .btn i {
    color: #fff !important;
    opacity: .5;
}

.gradient-header .header .form-inline .form-control::placeholder {
    color: #fff !important;
    opacity: .3;
}

.gradient-header .app-sidebar__toggle {
    background: rgba(0, 0, 0, 0.08);
}

.gradient-header .app-sidebar__toggle a {
    color: #fff;
}

.gradient-header .nav-link.icon {
    background: rgba(0, 0, 0, 0.08);
}

@media (min-width: 992px) {
    .gradient-header .hor-header.header .header-brand .header-brand-img.desktop-lgo {
        display: none;
    }

    .gradient-header .hor-header.header .header-brand .header-brand-img.dark-logo {
        display: block;
        margin-top: 5px;
    }
}

@media (max-width: 768px) and (min-width: 575.98px) {
    .gradient-header .app-header .header-brand .header-brand-img.dark-logo {
        display: block;
        margin-top: 5px;
    }

    .gradient-header .app-header .header-brand .header-brand-img.desktop-lgo,
    .gradient-header .app-header .header-brand .header-brand-img.mobile-logo,
    .gradient-header .app-header .header-brand .header-brand-img.darkmobile-logo {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .gradient-header .app-header .header-brand .header-brand-img.darkmobile-logo {
        display: block;
    }

    .gradient-header .app-header .header-brand .header-brand-img.mobile-logo {
        display: none !important;
    }

    .gradient-header .hor-header.header .header-brand-img.mobile-logo {
        display: none !important;
    }

    .gradient-header .hor-header.header .header-brand-img.darkmobile-logo {
        display: block;
        margin-top: 3px;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {

    .gradient-header .hor-header.header .header-brand-img.desktop-lgo,
    .gradient-header .hor-header.header .header-brand-img.mobile-logo {
        display: none !important;
    }

    .gradient-header .hor-header.header .header-brand-img.dark-logo {
        display: block !important;
        margin-top: 5px;
    }
}

.dark-mode.gradient-header .app-header,
.dark-mode.gradient-header .hor-header {
    background: linear-gradient(to left, #8133ff 0%, #3366ff 100%);
}

.dark-mode.gradient-header .header .nav-link.icon,
.dark-mode.gradient-header .animated-arrow {
    background: rgba(0, 0, 0, 0.1);
}

.dark-mode.gradient-header .header .form-inline .form-control {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    color: #fff;
}

/*---------- Leftmenu-styles ----------*/
/*Light-menu*/
.dark-mode.light-menu .app-sidebar {
    background: #fff;
}

.dark-mode.light-menu .app-sidebar .app-sidebar__user {
    border-bottom: 1px solid #e9ebfa;
}

.dark-mode.light-menu .app-sidebar .side-menu .slide a {
    color: #263871;
}

.dark-mode.light-menu .app-sidebar .side-menu .sidemenu_icon {
    color: #263871;
}

.dark-mode.light-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
    border: 3px solid #ebeff8;
}

.dark-mode.light-menu .app-sidebar .app-sidebar__user .user-pic::before {
    border: 2px solid #fff;
}

.dark-mode.light-menu .app-sidebar .app-sidebar__user .user-info h5 {
    color: #263871;
}

.dark-mode.light-menu .app-sidebar .Annoucement_card {
    background: #ebeff8;
}

.dark-mode.light-menu .app-sidebar .Annoucement_card .title,
.dark-mode.light-menu .app-sidebar .Annoucement_card .text-white {
    color: #263871 !important;
}

.dark-mode.light-menu .slide a.active .sidemenu_icon,
.dark-mode.light-menu .side-menu__item:hover .sidemenu_icon {
    color: #3366ff;
}

.dark-mode.light-menu .slide-menu li.active>a,
.dark-mode.light-menu .slide-menu li:hover>a {
    color: #3366ff !important;
}

@media (min-width: 768px) {
    .dark-mode.light-menu .app-sidebar__logo {
        border-bottom: 1px solid #e9ebfa;
        border-right: 1px solid #e9ebfa;
    }

    .dark-mode.light-menu .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .dark-mode.light-menu .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: none;
    }

    .dark-mode.light-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
        display: none;
    }

    .dark-mode.light-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .dark-mode.light-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: none;
    }
}

/*Color-menu*/
.color-menu .app-sidebar {
    background: #3366ff;
}

.color-menu .app-sidebar .side-item.side-item-category {
    color: #fff;
    opacity: .3;
}

.color-menu .app-sidebar .side-menu .slide a {
    color: #fff;
    opacity: .7;
}

.color-menu .app-sidebar .side-menu .sidemenu_icon {
    color: #fff;
    opacity: .7;
}

.color-menu .app-sidebar .slide a.active .sidemenu_icon,
.color-menu .app-sidebar .side-menu__item:hover .sidemenu_icon {
    color: #fff !important;
    opacity: inherit;
}

.color-menu .app-sidebar .side-menu .slide a.active,
.color-menu .app-sidebar .side-menu .slide a:hover {
    color: #fff !important;
    opacity: inherit;
}

.color-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.color-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.color-menu .app-sidebar .side-menu__item:hover .angle {
    color: #fff !important;
    opacity: inherit;
}

.color-menu .app-sidebar .side-menu__item.active:before {
    border-left: 3px solid #fff;
}

.color-menu .app-sidebar .app-sidebar__user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.color-menu .app-sidebar .app-sidebar__user .user-info h5 {
    color: #fff;
}

.color-menu .app-sidebar .app-sidebar__user .app-sidebar__user-name.text-muted {
    color: #fff !important;
    opacity: .7;
}

.color-menu .app-sidebar .Annoucement_card {
    background: #1f55f5;
}

.color-menu .app-sidebar .Annoucement_card .title,
.color-menu .app-sidebar .Annoucement_card .text-white,
.color-menu .app-sidebar .Annoucement_card .btn-outline {
    color: #fff !important;
}

@media (min-width: 768px) {
    .color-menu .app-sidebar__logo {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .color-menu .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .color-menu .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
        display: none;
    }

    .color-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo,
    .color-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.mobile-logo {
        display: none;
    }

    .color-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: block;
        margin-top: 5px;
    }

    .color-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .color-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo,
    .color-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: none;
    }
}

.dark-mode.color-menu .app-sidebar {
    background: #3366ff;
}

.dark-mode.color-menu .app-sidebar .side-menu .slide a {
    color: #fff;
    opacity: .7;
}

.dark-mode.color-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
    border: 3px solid #fff;
}

.dark-mode.color-menu .app-sidebar .app-sidebar__user .user-pic::before {
    border: 2px solid #fff;
}

.dark-mode.color-menu .side-item.side-item-category {
    color: #fff;
    opacity: .5;
}

.dark-mode.color-menu .app-sidebar ul li a {
    color: #fff;
    opacity: .8;
}

.dark-mode.color-menu .slide-menu li.active>a,
.dark-mode.color-menu .slide-menu li:hover>a {
    color: #fff !important;
    opacity: inherit;
}

.dark-mode.color-menu .app-sidebar .slide-menu .slide-item:hover,
.dark-mode.color-menu .app-sidebar .side-menu__item.active .side-menu__icon {
    color: #fff !important;
    opacity: inherit;
}

.dark-mode.color-menu .slide.is-expanded .side-menu__item {
    color: #fff !important;
    opacity: inherit;
}

@media (min-width: 768px) {
    .dark-mode.color-menu .app-sidebar__logo {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
}

/*dark-menu*/
.dark-menu .app-sidebar {
    background: #17263a;
}

.dark-menu .app-sidebar .side-item.side-item-category {
    color: #fff;
    opacity: .3;
}

.dark-menu .app-sidebar .side-menu .slide a {
    color: #fff;
    opacity: .7;
}

.dark-menu .app-sidebar .side-menu .sidemenu_icon {
    color: #fff;
    opacity: .7;
}

.dark-menu .app-sidebar .slide a.active .sidemenu_icon,
.dark-menu .app-sidebar .side-menu__item:hover .sidemenu_icon {
    color: #fff !important;
    opacity: inherit;
}

.dark-menu .app-sidebar .side-menu .slide a.active,
.dark-menu .app-sidebar .side-menu .slide a:hover {
    color: #fff !important;
    opacity: inherit;
}

.dark-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.dark-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.dark-menu .app-sidebar .side-menu__item:hover .angle {
    color: #fff !important;
    opacity: inherit;
}

.dark-menu .app-sidebar .side-menu__item.active:before {
    border-left: 3px solid #fff;
}

.dark-menu .app-sidebar .app-sidebar__user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-menu .app-sidebar .app-sidebar__user .user-info h5 {
    color: #fff;
}

.dark-menu .app-sidebar .app-sidebar__user .app-sidebar__user-name.text-muted {
    color: #fff !important;
    opacity: .5;
}

.dark-menu .app-sidebar .Annoucement_card {
    background: #20344e;
}

.dark-menu .app-sidebar .Annoucement_card .title,
.dark-menu .app-sidebar .Annoucement_card .text-white {
    color: #fff !important;
}

@media (min-width: 768px) {
    .dark-menu .app-sidebar__logo {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .dark-menu .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .dark-menu .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
        display: none;
    }

    .dark-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo,
    .dark-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.mobile-logo {
        display: none;
    }

    .dark-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: block;
        margin-top: 5px;
    }

    .dark-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .dark-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo,
    .dark-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: none;
    }
}

/*Graident-Color*/
.gradient-menu .app-sidebar {
    background: linear-gradient(to top, #7739fc 0%, #3a7afe 100%);
}

.gradient-menu .app-sidebar .side-item.side-item-category {
    color: #fff;
    opacity: .3;
}

.gradient-menu .app-sidebar .side-menu .slide a {
    color: #fff;
    opacity: .7;
}

.gradient-menu .app-sidebar .side-menu .sidemenu_icon {
    color: #fff;
    opacity: .7;
}

.gradient-menu .app-sidebar .slide a.active .sidemenu_icon,
.gradient-menu .app-sidebar .side-menu__item:hover .sidemenu_icon {
    color: #fff !important;
    opacity: inherit;
}

.gradient-menu .app-sidebar .side-menu .slide a.active,
.gradient-menu .app-sidebar .side-menu .slide a:hover {
    color: #fff !important;
    opacity: inherit;
}

.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.gradient-menu .app-sidebar .side-menu__item:hover .angle {
    color: #fff !important;
    opacity: inherit;
}

.gradient-menu .app-sidebar .side-menu__item.active:before {
    border-left: 3px solid #fff;
}

.gradient-menu .app-sidebar .app-sidebar__user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.gradient-menu .app-sidebar .app-sidebar__user .user-info h5 {
    color: #fff;
}

.gradient-menu .app-sidebar .app-sidebar__user .app-sidebar__user-name.text-muted {
    color: #fff !important;
    opacity: .7;
}

.gradient-menu .app-sidebar .Annoucement_card {
    background: #4747e2;
}

.gradient-menu .app-sidebar .Annoucement_card .title,
.gradient-menu .app-sidebar .Annoucement_card .text-white,
.gradient-menu .app-sidebar .Annoucement_card .btn-outline {
    color: #fff !important;
}

@media (min-width: 768px) {
    .gradient-menu .app-sidebar__logo {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .gradient-menu .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .gradient-menu .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
        display: none;
    }

    .gradient-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo,
    .gradient-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.mobile-logo {
        display: none;
    }

    .gradient-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: block;
        margin-top: 5px;
    }

    .gradient-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
    }

    .gradient-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo,
    .gradient-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: none;
    }
}

.dark-mode.gradient-menu .app-sidebar {
    background: linear-gradient(to top, #7739fc 0%, #3a7afe 100%);
}

.dark-mode.gradient-menu .app-sidebar .side-menu .slide a {
    color: #fff;
    opacity: .7;
}

.dark-mode.gradient-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
    border: 3px solid #fff;
}

.dark-mode.gradient-menu .app-sidebar .app-sidebar__user .user-pic::before {
    border: 2px solid #fff;
}

.dark-mode.gradient-menu .side-item.side-item-category {
    color: #fff;
    opacity: .5;
}

.dark-mode.gradient-menu .app-sidebar ul li a {
    color: #fff;
    opacity: .8;
}

.dark-mode.gradient-menu .slide-menu li.active>a,
.dark-mode.gradient-menu .slide-menu li:hover>a {
    color: #fff !important;
    opacity: inherit;
}

.dark-mode.gradient-menu .app-sidebar .slide-menu .slide-item:hover,
.dark-mode.gradient-menu .app-sidebar .side-menu__item.active .side-menu__icon {
    color: #fff !important;
    opacity: inherit;
}

.dark-mode.gradient-menu .slide.is-expanded .side-menu__item {
    color: #fff !important;
    opacity: inherit;
}

@media (min-width: 768px) {
    .dark-mode.gradient-menu .app-sidebar__logo {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
}

/*---------- Horizontalmenu-styles ----------*/
/*Light-hormenu*/
.light-hormenu .horizontal-main.hor-menu {
    background: #fff;
}

.light-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
    color: #171a29;
}

.light-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu:before {
    border-bottom: 10px solid 1px solid #e9ebfa;
}

.light-hormenu .mega-menubg:before {
    border-bottom: 10px solid 1px solid #e9ebfa;
}

@media only screen and (max-width: 991px) {
    .light-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: #fff;
    }

    .light-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
        color: #171a29;
    }

    .light-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
        background-color: #f1f5ff;
    }

    .light-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        color: #171a29;
    }
}

.dark-mode.light-hormenu .horizontal-main.hor-menu {
    background: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode.light-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
    color: #171a29;
}

.dark-mode.light-hormenu.light-header .horizontal-main.hor-menu {
    box-shadow: none;
}

@media only screen and (max-width: 991px) {
    .dark-mode.light-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: #fff;
    }

    .dark-mode.light-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
        color: #171a29;
    }

    .dark-mode.light-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
        background-color: #f1f5ff;
    }

    .dark-mode.light-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        color: #171a29;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list {
        border-right: 1px solid #e9ebfa;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
        border-bottom-color: #e9ebfa;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i {
        color: #576482;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu,
    .dark-mode.light-hormenu .horizontal-main.hor-menu .mega-menubg {
        background: #f1f4fb !important;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .sub-menu li a:before,
    .dark-mode.light-hormenu .horizontal-main.hor-menu .mega-menubg li a:before {
        color: #728096;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        color: #576482;
    }

    .dark-mode.light-hormenu .horizontal-main.hor-menu .horizontalMenu h3 {
        color: #263871;
    }
}

/*color-hormenu*/
.color-hormenu .horizontal-main.hor-menu {
    background: #3366ff;
}

.color-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
    color: #fff;
    opacity: 0.8;
}

.color-hormenu .horizontalMenu>.horizontalMenu-list>li>a>.fa {
    color: #fff;
    opacity: 0.8;
}

.color-hormenu .horizontalMenu>.horizontalMenu-list>li>a.active,
.color-hormenu .horizontalMenu>.horizontalMenu-list>li>a:hover,
.color-hormenu .horizontalMenu>.horizontalMenu-list>li>a>.fa {
    color: #fff !important;
    opacity: inherit;
}

@media only screen and (max-width: 991px) {
    .color-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: #3366ff;
    }

    .color-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
        color: #fff;
        opacity: 0.8;
    }

    .color-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        color: #fff;
        opacity: 0.8;
    }

    .color-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active,
    .color-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
        color: #fff !important;
        opacity: inherit;
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list {
        border-right: 1px solid rgba(255, 255, 255, 0.07);
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
        border-bottom-color: rgba(255, 255, 255, 0.07);
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i {
        color: rgba(255, 255, 255, 0.4);
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu,
    .color-hormenu .horizontal-main.hor-menu .mega-menubg {
        background: #4573ff !important;
    }

    .color-hormenu .horizontal-main.hor-menu .sub-menu li a:before,
    .color-hormenu .horizontal-main.hor-menu .mega-menubg li a:before {
        color: rgba(255, 255, 255, 0.3);
    }

    .color-hormenu .horizontal-main.hor-menu .sub-menu li a.active:before,
    .color-hormenu .horizontal-main.hor-menu .sub-menu li a:hover:before,
    .color-hormenu .horizontal-main.hor-menu .mega-menubg li a.active:before,
    .color-hormenu .horizontal-main.hor-menu .mega-menubg li a:hover:before {
        color: #fff !important;
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active,
    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
        color: #fff !important;
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .color-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        color: rgba(255, 255, 255, 0.6);
    }

    .color-hormenu .horizontal-main.hor-menu .horizontalMenu h3 {
        color: #fff;
    }
}

.dark-mode.color-hormenu .horizontal-main.hor-menu {
    background: #3366ff;
    box-shadow: none;
}

@media only screen and (max-width: 991px) {
    .dark-mode.color-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: #3366ff;
    }
}

/*Gradient-hormenu*/
.gradient-hormenu .horizontal-main.hor-menu {
    background: linear-gradient(to left, #8133ff 0%, #3366ff 100%);
}

.gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
    color: #fff;
    opacity: 0.8;
}

.gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>a>.fa {
    color: #fff;
    opacity: 0.8;
}

.gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>a.active,
.gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>a:hover,
.gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>a>.fa {
    color: #fff !important;
    opacity: inherit;
}

@media only screen and (max-width: 991px) {
    .gradient-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: linear-gradient(to top, #7739fc 0%, #3a7afe 100%);
    }

    .gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
        color: #fff;
        opacity: 0.8;
    }

    .gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        color: #fff;
        opacity: 0.8;
    }

    .gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active,
    .gradient-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
        color: #fff !important;
        opacity: inherit;
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list {
        border-right: 1px solid rgba(255, 255, 255, 0.07);
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
        border-bottom-color: rgba(255, 255, 255, 0.07);
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i {
        color: rgba(255, 255, 255, 0.4);
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu,
    .gradient-hormenu .horizontal-main.hor-menu .mega-menubg {
        background: #4573ff !important;
    }

    .gradient-hormenu .horizontal-main.hor-menu .sub-menu li a:before,
    .gradient-hormenu .horizontal-main.hor-menu .mega-menubg li a:before {
        color: rgba(255, 255, 255, 0.3);
    }

    .gradient-hormenu .horizontal-main.hor-menu .sub-menu li a.active:before,
    .gradient-hormenu .horizontal-main.hor-menu .sub-menu li a:hover:before,
    .gradient-hormenu .horizontal-main.hor-menu .mega-menubg li a.active:before,
    .gradient-hormenu .horizontal-main.hor-menu .mega-menubg li a:hover:before {
        color: #fff !important;
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active,
    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
        color: #fff !important;
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        color: rgba(255, 255, 255, 0.6);
    }

    .gradient-hormenu .horizontal-main.hor-menu .horizontalMenu h3 {
        color: #fff;
    }
}

.dark-mode.gradient-hormenu .horizontal-main.hor-menu {
    background: linear-gradient(to left, #8133ff 0%, #3366ff 100%);
    box-shadow: none;
}

@media only screen and (max-width: 991px) {
    .dark-mode.gradient-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: linear-gradient(to top, #7739fc 0%, #3a7afe 100%);
    }
}

.dark-mode.color-header.gradient-menu .app-sidebar {
    box-shadow: none;
}

.dark-mode.gradient-header.gradient-header .app-sidebar {
    box-shadow: none;
}

.dark-mode.color-menu.color-header .app-sidebar {
    box-shadow: none;
}

.dark-mode.light-menu.color-header .app-sidebar {
    box-shadow: none;
}

/*dark-hormenu*/
.dark-hormenu .horizontal-main.hor-menu {
    background: #17263a;
}

.dark-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
    color: #fff;
    opacity: 0.8;
}

.dark-hormenu .horizontalMenu>.horizontalMenu-list>li>a>.fa {
    color: #fff;
    opacity: 0.8;
}

.dark-hormenu .horizontalMenu>.horizontalMenu-list>li>a.active,
.dark-hormenu .horizontalMenu>.horizontalMenu-list>li>a:hover,
.dark-hormenu .horizontalMenu>.horizontalMenu-list>li>a>.fa {
    color: #fff !important;
    opacity: inherit;
}

@media only screen and (max-width: 991px) {
    .dark-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: #17263a;
    }

    .dark-hormenu .horizontalMenu>.horizontalMenu-list>li>a {
        color: #fff;
        opacity: 0.8;
    }

    .dark-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        color: #fff;
        opacity: 0.8;
    }

    .dark-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active,
    .dark-hormenu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
        color: #fff !important;
        opacity: inherit;
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list {
        border-right: 1px solid rgba(255, 255, 255, 0.07);
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
        border-bottom-color: rgba(255, 255, 255, 0.07);
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i {
        color: rgba(255, 255, 255, 0.4);
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu,
    .dark-hormenu .horizontal-main.hor-menu .mega-menubg {
        background: #1b2c42 !important;
    }

    .dark-hormenu .horizontal-main.hor-menu .sub-menu li a:before,
    .dark-hormenu .horizontal-main.hor-menu .mega-menubg li a:before {
        color: rgba(255, 255, 255, 0.3);
    }

    .dark-hormenu .horizontal-main.hor-menu .sub-menu li a.active:before,
    .dark-hormenu .horizontal-main.hor-menu .sub-menu li a:hover:before,
    .dark-hormenu .horizontal-main.hor-menu .mega-menubg li a.active:before,
    .dark-hormenu .horizontal-main.hor-menu .mega-menubg li a:hover:before {
        color: #fff !important;
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active,
    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
        color: #fff !important;
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        color: rgba(255, 255, 255, 0.6);
    }

    .dark-hormenu .horizontal-main.hor-menu .horizontalMenu h3 {
        color: #fff;
    }
}

.dark-mode.dark-hormenu .horizontal-main.hor-menu {
    background: #3366ff;
    box-shadow: none;
}

@media only screen and (max-width: 991px) {
    .dark-mode.dark-hormenu.active .horizontalMenu>.horizontalMenu-list {
        background: #3366ff;
    }
}

.gradient-header.color-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.dark-header.color-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.color-header.color-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.gradient-header.gradient-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.dark-header.gradient-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.color-header.gradient-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.gradient-header.dark-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.dark-header.dark-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.color-header.dark-menu .app-sidebar {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

@media (min-width: 991px) {
    .dark-hormenu.gradient-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .dark-hormenu.dark-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .dark-hormenu.color-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .color-hormenu.gradient-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .color-hormenu.dark-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .color-hormenu.color-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .gradient-hormenu.gradient-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .gradient-hormenu.dark-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    .gradient-hormenu.color-header .header.hor-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }
}
/*# sourceMappingURL=skin-modes.css.map */
